<template>
  <div class="animated fadeIn">

    <b-row>

      <b-col sm="12">
        <b-card>

          <form @submit.stop.prevent="onSubmit">

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="name">Name</label>
                  <b-form-input type="text" id="name" v-model="name" placeholder="Customer name" name="name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="postcode">Postcode</label>
                  <b-form-input type="text" id="postcode" v-model="postcode" placeholder="Customer postcode" name="postcode"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="name">Email</label>
                  <b-form-input type="text" id="email" v-model="email" placeholder="Email address" name="email"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="date_of_birth">Date Of Birth</label>
                  <b-form-input type="text" id="date_of_birth" v-model="date_of_birth" v-mask="'##/##/####'" placeholder="Date of Birth " name="date_of_birth"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div slot="footer">
              <b-button :to="{ name: 'Search' }" size="md" variant="outline-secondary" class="pull-left">
                Back
              </b-button>
              <b-button type="submit" size="md" variant="primary" class="pull-right">
                Submit
              </b-button>
            </div>

          </form>

        </b-card>

      </b-col>

    </b-row>

    <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table" ref="table" v-show="show">

      <template slot="actions" slot-scope="props">
        <b-button variant="secondary" :to="{ name: 'Customer', params: { id: props.row.id } }">View</b-button>
      </template>

    </v-server-table>

  </div>
</template>

<script>
import Vue from 'vue'
import {ServerTable} from 'vue-tables-2'
import axios from '../../../shared/axios/auth'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {mask} from 'vue-the-mask'

Vue.use(ServerTable)

export default {
  components: {
    ServerTable
  },
  directives: {mask},
  mixins: [validationMixin],
  data () {
    return {
      show: false,
      search: this.$route.params.search,
      searchselected: null,
      name: null,
      postcode: null,
      date_of_birth: null,
      email: null,
      // Data Table
      columns: [
          'first_name', 'last_name', 'email', 'actions'
      ],
      data: [],
      fields: [
      ],
      options: {
        requestFunction: (data) => {
          let new_params  = {};
          let n_params = {
            product: localStorage.getItem('selected_product'),
            filter: this.searchselected

          };
          let params_ = {}
          if (this.name) {
            params_.name = this.name
          }
          if (this.postcode) {
            params_.postcode = this.postcode
          }
          if (this.date_of_birth) {

            let dob_string = this.date_of_birth;
            let split_dob = dob_string.split("/")

            let day = split_dob[0]
            let month = split_dob[1]
            let year = split_dob[2]

            params_.dob = year + '-' + month + '-' + day
          }
          if (this.status) {
            params_.status = this.status
          }
          if (this.email) {
            params_.email = this.email
          }
          new_params = Object.assign({}, params_, data, n_params)
          
          return axios.get('/users/customer/search/', {
              params: new_params
          }).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        },
        responseAdapter: function(resp) {
          console.log('adapter');
          var data = this.getResponseData(resp);
          this.show = 'true'
          return { data: data.data.data, count: data.data.pagination.total }
        },
        headings: {},
        sortable: [],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        filterable: false
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default'
    }
  },
  validations: {
  },
  methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },
    onSubmit () {
        //this.showAPIError = false;
        this.$v.$touch();
        if (this.$v.$anyError) {
          return;
        }

        let search_params = '';
        if (this.name) {
          search_params += 'name=' + this.name
        }
        if (this.postcode) {
            if (search_params) {
              search_params += '&postcode=' + this.postcode
            } else {
              search_params += 'postcode=' + this.postcode
            }
        }
        if (this.email) {
            if (search_params) {
              search_params += '&email=' + this.email
            } else {
              search_params += 'email=' + this.email
            }
        }
        if (this.date_of_birth) {
            if (search_params) {
              search_params += '&dob=' + this.date_of_birth
            } else {
              search_params += 'dob=' + this.date_of_birth
            }
        }

        axios.get('/users/customer/search/?' + search_params).then(res => {
          this.data = res
          this.$refs.table.refresh();
          this.show = 'true'

        }).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
              console.log('post error');
              console.log(error);
          })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
